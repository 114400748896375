export enum SelectTypeList {
    View = 1,
    Response = 2,
    Logic = 3,
}

export enum SelectType {
    View = 'View',
    Http = 'Http',
    Logic = 'Logic',
}

export enum LogicSelectType {
    Default = 'default',
    Less = '<',
    Equal = '=',
    UnEqual = "!=",
    More = '>',
    LessOrEqual = '<=',
    MoreOrEqual = '>=',
    StartsWith = 'startsWith',
    EndsWith = 'endsWith',
    Contains = 'contains',
    Shorter = 'shorter',
    Longer = 'longer',
    After = 'after',
    Before = 'before',
}

export interface HttpSelectProps {
    code: string
}

export interface LogicSelectProps {
    type: LogicSelectType
    value: string
    not: boolean
}

export interface SelectModel {
    id?: string
    type: SelectType
    title: string
    view?: string
    target: string
    response: {
        httpProps?: HttpSelectProps
        logicProps?: LogicSelectProps
    }
}

export class SelectDefaults {
    public static DefaultHttpProps: HttpSelectProps = {
        code: '***',
    }

    public static DefaultLogicProps: LogicSelectProps = {
        not: false,
        type: LogicSelectType.Less,
        value: '',
    }
}
