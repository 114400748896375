import {
    Box,
    Button,
    ImageList,
    ImageListItem,
    Typography,
} from '@mui/material'
import { Flow } from '../../models/flowModel'
import { useEffect, useMemo, useRef, useState } from 'react'
import { Data } from '../../models/dataModel'
import { DataService } from '../../services/DataService'
import Qrcode from 'qrcode.react'
import { useReactToPrint } from 'react-to-print'
import PrintIcon from '@mui/icons-material/Print'

export interface FlowShareAreaProps {
    flow: Flow
}

const FlowShareArea = ({ flow }: FlowShareAreaProps) => {
    const [data, setData] = useState<Data[]>([])
    const dataService = useMemo(() => new DataService(flow), [flow])
    const componentRef = useRef(null)

    useEffect(() => {
        const loadAll = async () => {
            setData((await dataService.getDataByFlow()) ?? [])
        }
        loadAll()
    }, [dataService, flow])

    function RenderMainQRCode(): import('react').ReactNode {
        return (
            <Box sx={{ padding: '4em' }}>
                <Qrcode
                    value={
                        `${process.env.REACT_APP_SCANGUIDE_VIEW_URL}/${flow.id}`
                    }
                ></Qrcode>
                <a
                    href={`${process.env.REACT_APP_SCANGUIDE_VIEW_URL}/${flow.id}`}
                ><Typography variant='h6'>{flow.name}</Typography></a>
                
            </Box>
        )
    }

    function RenderAllQRCodes() {
        return (
            <ImageList cols={4}>
                {data.map(entry => {
                    return (
                        <ImageListItem
                            key={'qr_code_' + entry.id}
                            sx={{ padding: '4em' }}
                        >
                            <Qrcode
                                value={`${process.env.REACT_APP_SCANGUIDE_VIEW_URL}/${flow.id}?data=${entry.id}`}
                            ></Qrcode>
                            <Typography variant='h6'>
                                <a
                                    href={`${process.env.REACT_APP_SCANGUIDE_VIEW_URL}/${flow.id}?data=${entry.id}`}
                                >
                                    {flow.name + '( ' + entry.name + ')'}
                                </a>
                            </Typography>
                        </ImageListItem>
                    )
                })}
            </ImageList>
        )
    }

    const onPrintClickEvent = useReactToPrint({
        content: () => componentRef.current,
    })

    return (
        <>
            <Button onClick={onPrintClickEvent}>
                <PrintIcon />
            </Button>
            <div className='flowShareArea' ref={componentRef}>
                <Typography variant='h4'>{flow.name}</Typography>
                <Typography variant='h6'>{flow.description}</Typography>
                {data.length > 0 && RenderAllQRCodes()}
                {data.length === 0 && RenderMainQRCode()}
            </div>
        </>
    )
}

export default FlowShareArea
