import Keycloak, {
    KeycloakInitOptions,
    KeycloakLoginOptions,
    KeycloakRegisterOptions,
} from 'keycloak-js'
import APIService from './APIService'
import { User } from '../models/userModel'
import { v4 as uuidv4 } from 'uuid'
import { CustomerDataModel } from '../models/customerData'

export enum AuthLocalStorageKeys {
    AUTH_TOKEN = 'auth_token',
    REFRESH_TOKEN = 'refresh_token',
}

export type AuthConfig = {
    url: string
    realm: string
    clientId: string
}

class AuthService {
    static instance: AuthService

    static getInstance() {
        if (AuthService.instance) {
            return AuthService.instance
        } else {
            console.error(
                "AuthService instance doesn't exist. Use new AuthService(config) instead",
            )
            return null
        }
    }

    public readonly keycloak: Keycloak
    private initialized = false

    constructor(config: AuthConfig) {
        if (AuthService.instance) {
            throw new Error(
                'AuthService instance already existed. Use AuthService.getInstance() instead',
            )
        }
        this.keycloak = new Keycloak({
            url: config.url,
            realm: config.realm,
            clientId: config.clientId,
        })
        AuthService.instance = this
    }

    public async initialize(options?: KeycloakInitOptions) {
        const token =
            localStorage.getItem(AuthLocalStorageKeys.AUTH_TOKEN) ?? undefined
        const refreshToken =
            localStorage.getItem(AuthLocalStorageKeys.REFRESH_TOKEN) ??
            undefined

        await this.keycloak.init({
            token,
            refreshToken,
            checkLoginIframe: false,
            ...options,
        })

        localStorage.setItem(
            AuthLocalStorageKeys.AUTH_TOKEN,
            this.keycloak.token ?? '',
        )
        localStorage.setItem(
            AuthLocalStorageKeys.REFRESH_TOKEN,
            this.keycloak.refreshToken ?? '',
        )

        this.initialized = true

        return true
    }

    get isLoggedIn() {
        return this.keycloak.authenticated
    }

    get profile() {
        return this.keycloak.loadUserProfile()
    }

    public async login() {
        const options: KeycloakLoginOptions = {
            redirectUri: `${process.env.REACT_APP_SCANGUIDE_URL}/edit/flows`,
        }
        await AuthService.instance.keycloak.login(options)
    }

    public async refresh() {
        if (this.initialized && this.isLoggedIn === true) {
            AuthService.instance.keycloak.updateToken(100)
        }
    }

    public async register() {
        const options: KeycloakRegisterOptions = {
            redirectUri: process.env.SCANGUIDE_REDIRECT_URL,
        }
        await AuthService.instance.keycloak.register(options)
    }

    public async logout() {
        if (this.initialized) {
            const options: KeycloakLoginOptions = {
                redirectUri: `${process.env.REACT_APP_SCANGUIDE_URL}/edit/flows`,
            }
            AuthService.instance.keycloak.logout(options)
            localStorage.setItem(AuthLocalStorageKeys.AUTH_TOKEN, '')
            localStorage.setItem(AuthLocalStorageKeys.REFRESH_TOKEN, '')
        }
    }

    public async getMe(): Promise<User | undefined> {
        if (this.initialized) {
            return await APIService.jsonRequest<User>(`/me`, 'GET')
        } else {
            return {
                name: '',
                id: uuidv4(),
                eMail: '',
                customerData: new CustomerDataModel(),
            }
        }
    }
}
export default AuthService
