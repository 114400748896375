import {
    Box,
    Button,
    Stack,
    Table,
    TableBody,
    TableCell,
    TableRow,
    Typography,
} from '@mui/material'
import { useLocation, useParams } from 'react-router-dom'
import { Flow } from '../../models/flowModel'
import { useEffect, useMemo, useRef, useState } from 'react'
import {
    EmbeddedProps,
    EmbeddedType,
    ModelDefaults,
    View,
} from '../../models/viewModel'
import { ViewService } from '../../services/ViewService'
import { FlowService } from '../../services/FlowService'
import { SelectModel } from '../../models/selectModel'
import { getStyleByUserId } from '../../services/UserService'
import { ViewType } from '../../models/viewType'
import { SelectService } from '../../services/SelectService'
import MDEditor from '@uiw/react-md-editor'
import HomeIcon from '@mui/icons-material/Home'
import { SessionService } from '../../services/SessionService'
import { RedirectView } from '../../models/viewModel'
import SelfInputField from '../Base/SelfInputField'
import { Session } from '../../models/sessionModel'
import { v4 as uuidv4 } from 'uuid'
import './MainPage.css'
import { CustomerIdentityDataModel } from '../../models/customerData'
import { ImprintComponent } from '../imprint'
import fontService from '../../services/FontService'

const MainPage = () => {
    const viewIdRef = useRef<string>('')
    const { flowId, viewId } = useParams()
    const queryParams = useLocation()
    const dataId = new URLSearchParams(queryParams.search).get('data')
    const [sessionId, setSessionId] = useState<string | null>(
        new URLSearchParams(queryParams.search).get('sessionId'),
    )
    const [error, setError] = useState<string | undefined>(undefined)
    const [logoURL, setLogoURL] = useState<string | undefined>(undefined)

    const [, setFlow] = useState<Flow | undefined>(undefined)
    const [view, setView] = useState<View | undefined>(undefined)
    const [embedType, setEmbedType] = useState<EmbeddedType>(EmbeddedType.EMBED)
    const [selects, setSelects] = useState<SelectModel[]>([])
    const flowService = useMemo(() => new FlowService(), [])
    const [bgColor, setBackGroundColor] = useState<string>('#FFFFFF')
    const [primColor, setPrimaryColor] = useState<string>('#000000')
    const [, setSecondaryColor] = useState<string>('#000000')
    const [btnColor, setButtonColor] = useState<string>('#BBBBDD')
    const [btnTxtColor, setButtontextColor] = useState<string>('#007700')
    const [fontName, setFontName] = useState<string>('Comic Sans MS')

    const sessionService = useMemo(() => new SessionService(), [])
    const [session, setSession] = useState<Session | undefined>()

    useEffect(() => {
        console.log(fontName)
        const saveFont = async () => {
            await fontService.useFont(fontName)
        }

        saveFont()
    }, [fontName])

    useEffect(() => {
        const createNewSession = async (): Promise<void> => {
            const nextSession = await sessionService.createSession({
                id: uuidv4(),
                flow: flowId ?? '',
                variables: {},
            })
            setSessionId(nextSession?.id ?? '')
            setSession(nextSession)
        }

        const loadSession = async (): Promise<void> => {
            const loadedSession = await sessionService.getSessionById(
                sessionId ?? '',
            )
            setSessionId(sessionId)
            setSession(loadedSession)
        }
        if (!session) {
            if (sessionId === null || sessionId === undefined) {
                createNewSession()
            } else {
                loadSession()
            }
        }
    }, [sessionId, flowId, session, sessionService])

    const onUpdateSessionValue = (key: string, value: string) => {
        if (session !== undefined && session !== null) {
            session.variables[key] = value
        }
    }

    useEffect(() => {
        const loadViewAndFlow = async () => {
            console.log('FlowId in UseEffect: ' + flowId)
            try {
                const loadedFlow = await flowService.getFlowById(
                    flowId ?? '',
                    dataId,
                )
                viewIdRef.current = viewId ?? ''
                if (viewId === undefined || viewId === null || viewId === '') {
                    console.log(
                        "ViewID is undefined, let's get it from the Flow: ",
                    )
                    viewIdRef.current = (loadedFlow?.firstView ?? '').toString()
                    console.log('Now, ViewID is ' + viewIdRef.current)
                }
                if (loadedFlow?.style && loadedFlow.style.logo) {
                    setLogoURL(loadedFlow.style.logo)
                }

                let userCI: CustomerIdentityDataModel | undefined = {
                    backgroundColor: '#FFFFFF',
                    buttonColor: '#0000BB',
                    buttonFontColor: '#FFFFFF',
                    fontColor: '#000000',
                    mainFont: 'Arial',
                    secondaryColor: '#DDDDDD',
                    logoURL: 'https://app.my-clickguide.de/ScanGuide_Logo.png',
                }
                if (loadedFlow?.user) {
                    userCI = await getStyleByUserId(loadedFlow.user ?? '')
                }

                setBackGroundColor(userCI?.backgroundColor ?? '#FFFFFF')
                setButtonColor(userCI?.buttonColor ?? '#0000BB')
                setButtontextColor(userCI?.buttonFontColor ?? '#FFFFFF')
                setPrimaryColor(userCI?.fontColor ?? '#000000')
                setSecondaryColor(userCI?.secondaryColor ?? '#DDDDDD')
                setFontName(userCI?.mainFont ?? 'Arial')
                document.body.style.backgroundColor =
                    userCI?.backgroundColor ?? '#FFFFFF'

                const viewService = new ViewService(viewIdRef.current)
                const selectService = new SelectService(viewIdRef.current)
                const getViewResult = await viewService.executeViewById(
                    viewIdRef.current,
                    dataId,
                    sessionId,
                )
                const loadedSelects = await selectService.getAll(dataId)
                if (
                    getViewResult?.redirect !== undefined &&
                    getViewResult?.redirect !== null
                ) {
                    const redirect: RedirectView = getViewResult.redirect
                    let url: string =
                        process.env.REACT_APP_SCANGUIDE_URL +
                        '/view/' +
                        redirect.flowId +
                        '/' +
                        redirect.target
                    if (redirect.dataId) {
                        url += '?data=' + redirect.dataId
                        if (redirect.sessionId) {
                            url += '&sessionId=' + redirect.sessionId
                        }
                    } else {
                        if (redirect.sessionId) {
                            url += '?sessionId=' + redirect.sessionId
                        }
                    }
                    window.location.replace(url)
                }
                if (getViewResult?.view === undefined) {
                    return
                }
                const loadedView: View = getViewResult.view
                if (loadedView.type === ViewType.Embed) {
                    console.log('Embedded View recognized.')
                    const props: EmbeddedProps =
                        loadedView?.parameters?.embeddedProps ??
                        ModelDefaults.defaultEmbeddedProps
                    setEmbedType(props.embedType)
                }
                setFlow(loadedFlow)
                setView(loadedView)
                setSelects(loadedSelects ?? [])
            } catch (ex) {
                setError('Unauthorized or a Bug')
            }
        }
        loadViewAndFlow()
    }, [dataId, flowId, flowService, sessionId, viewId])

    const RenderInputs = () => {
        return (
            <Table>
                <TableBody>
                    {view?.parameters &&
                        view?.parameters?.descriptionProps &&
                        view?.parameters?.descriptionProps.inputs &&
                        view?.parameters?.descriptionProps.inputs.map(entry => {
                            return (
                                <TableRow key={'session_key_' + entry.name}>
                                    <TableCell>{entry.name}</TableCell>
                                    <TableCell>
                                        <SelfInputField
                                            value={
                                                (session?.variables[
                                                    entry.variable
                                                ] as string) ?? ''
                                            }
                                            onChange={e =>
                                                onUpdateSessionValue(
                                                    entry.variable,
                                                    e,
                                                )
                                            }
                                        />
                                    </TableCell>
                                </TableRow>
                            )
                        })}
                </TableBody>
            </Table>
        )
    }

    const renderView = (_embedType: EmbeddedType) => {
        if (error !== undefined) {
            return <h1>Unauthorized: {error}</h1>
        }
        const props = view?.parameters?.embeddedProps

        switch (view?.type) {
            case ViewType.View:
                return (
                    <>
                        <MDEditor.Markdown
                            source={view.description ?? ''}
                            style={{
                                backgroundColor: bgColor,
                                color: primColor,
                                fontFamily: fontName,
                                maxHeight: '90%',
                            }}
                        />
                        <RenderInputs />
                    </>
                )
            case ViewType.Error:
                return (
                    <>
                        <MDEditor.Markdown
                            source={view.description ?? ''}
                            style={{
                                backgroundColor: bgColor,
                                color: primColor,
                                fontFamily: fontName,
                                overflow: 'scroll',
                                maxHeight: '90%',
                            }}
                        />
                        <RenderInputs />
                    </>
                )
            case ViewType.Embed:
                if (!props) {
                    return <></>
                }
                switch (_embedType) {
                    case EmbeddedType.EMBED:
                        return (
                            <>
                                <iframe
                                    src={props?.url ?? ''}
                                    title={view.title}
                                    height={500}
                                ></iframe>
                                <RenderInputs />
                            </>
                        )
                    case EmbeddedType.EXTERNAL:
                        return (
                            <>
                                <Button>
                                    <a
                                        href={props?.url ?? ''}
                                        target='_blank'
                                        rel='noreferrer'
                                    >
                                        Go to {props?.url ?? ''}
                                    </a>
                                </Button>
                                <RenderInputs />
                            </>
                        )
                    case EmbeddedType.REDIRECT:
                        window.location.href =
                            props?.url ?? 'https://app.scanguide.de'
                        return <></>
                    default:
                        return <></>
                }
            case ViewType.HttpCall:
                console.log('HttpCall')
                return (
                    <>
                        <Typography variant='h3'>
                            Loading Http Call...
                        </Typography>
                    </>
                )
            case ViewType.Logic:
                console.log('Logic Call')
                return (
                    <>
                        <Typography variant='h3'>Loading Logic...</Typography>
                    </>
                )
            default:
                return (
                    <>
                        <Typography variant='h3'>Loading...</Typography>
                    </>
                )
        }
    }

    const GetURL = (
        flow: string | undefined,
        target: string,
        _dataId: string | null = null,
        _sessionId: string | null,
    ): string => {
        let result = ''
        if (_sessionId !== null && _sessionId !== '') {
            if (_dataId === undefined || _dataId === null || _dataId === '') {
                result = `${process.env.REACT_APP_SCANGUIDE_VIEW_URL}/${flow}/${target}?sessionId=${_sessionId}`
            } else {
                result = `${process.env.REACT_APP_SCANGUIDE_VIEW_URL}/${flow}/${target}?data=${_dataId}&sessionId=${_sessionId}`
            }
        } else {
            if (_dataId === undefined || _dataId === null) {
                result = `${process.env.REACT_APP_SCANGUIDE_VIEW_URL}/${flow}/${target}`
            } else {
                result = `${process.env.REACT_APP_SCANGUIDE_VIEW_URL}/${flow}/${target}?data=${_dataId}`
            }
        }
        return result
    }

    const GetFlowURL = (
        flow: string | undefined,
        _dataId: string | null = null,
        _sessionId: string | null = null,
    ): string => {
        let result = ''
        if (_sessionId !== null && _sessionId !== '') {
            if (_dataId === undefined || _dataId === null) {
                result = `${process.env.REACT_APP_SCANGUIDE_VIEW_URL}/${flow}?sessionId=${_sessionId}`
            } else {
                result = `${process.env.REACT_APP_SCANGUIDE_VIEW_URL}/${flow}?data=${_dataId}&sessionId=${_sessionId}`
            }
        } else {
            if (dataId === undefined || dataId === null) {
                result = `${process.env.REACT_APP_SCANGUIDE_VIEW_URL}/${flow}`
            } else {
                result = `${process.env.REACT_APP_SCANGUIDE_VIEW_URL}/${flow}?data=${_dataId}`
            }
        }
        return result
    }

    const GoToURL = async (url: string) => {
        if (session !== undefined && session.id !== undefined) {
            await sessionService.updateSession(session?.id, session)
        } else {
            console.log('Session could not be found!')
        }

        window.location.replace(url)
    }

    return (
        <div className='viewer-center-container'>
            <div
                className='viewer-centered-div'
                style={{ backgroundColor: bgColor }}
            >
                <Stack>
                    <div
                        className='viewer-navigation-div'
                        style={{ backgroundColor: bgColor, width: '100%' }}
                    >
                        <Box sx={{ float: 'left', width: 100 }}>
                            <a href={GetFlowURL(flowId, dataId, sessionId)}>
                                <HomeIcon style={{ color: btnColor }} />
                            </a>
                        </Box>
                        {logoURL && (
                            <Box
                                component='img'
                                sx={{
                                    maxHeight: { xs: 100, md: 80 },
                                    maxWidth: { xs: 200, md: 150 },
                                    float: 'right',
                                }}
                                alt='Company Logo'
                                src={logoURL}
                            />
                        )}
                    </div>
                    <Typography
                        variant='h3'
                        style={{
                            color: primColor,
                            fontFamily: fontName,
                        }}
                    >
                        {view?.title}
                    </Typography>
                    <hr
                        style={{
                            color: primColor,
                            borderRight: 0,
                            borderLeft: 0,
                        }}
                    />
                    {renderView(embedType)}
                    <hr
                        style={{
                            color: primColor,
                            borderRight: 0,
                            borderLeft: 0,
                        }}
                    />
                    <Table>
                        <TableBody
                            className='viewer-selects-list'
                            style={{ backgroundColor: bgColor }}
                        >
                            {selects.map(entry => (
                                <TableRow key={'selects_' + (entry.id ?? '')}>
                                    <TableCell>
                                        <Button
                                            sx={{
                                                width: '100%',
                                                backgroundColor: btnColor,
                                                color: btnTxtColor,
                                                borderWidth: '2px',
                                                borderColor: '#000000',
                                            }}
                                            onClick={() => {
                                                GoToURL(
                                                    GetURL(
                                                        flowId,
                                                        entry.target,
                                                        dataId,
                                                        sessionId,
                                                    ),
                                                )
                                            }}
                                        >
                                            {entry.title} &gt;&gt;
                                        </Button>
                                    </TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </Stack>

                <ImprintComponent flowId={flowId} />
            </div>
        </div>
    )
}

export default MainPage
