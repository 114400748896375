import React, { useState, useEffect, useMemo } from 'react'
import {
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Paper,
    Button,
    Tooltip,
} from '@mui/material'
import { FlowService } from '../../services/FlowService' // Adjust the import path as needed
import { Flow } from '../../models/flowModel' // Adjust the import path as needed
import { Link } from 'react-router-dom' // Ensure you have 'react-router-dom' installed
import { ViewService } from '../../services/ViewService'
import { ViewType } from '../../models/viewType'
import AddIcon from '@mui/icons-material/Add'
import PreviewIcon from '@mui/icons-material/Preview'
import DeleteForeverIcon from '@mui/icons-material/DeleteForever'
import OkCancelDialogButton from '../Alert/OkCancelDialogButton'
import EditIcon from '@mui/icons-material/Edit'
import UploadIcon from '@mui/icons-material/Upload'
import { v4 as uuidv4 } from 'uuid'
import FileUploadDialogButton from '../Alert/FileUploadDialogButton'
import { User } from '../../models/userModel'
import AuthService from '../../services/AuthService'

const FlowListComponent = () => {
    const flowService = useMemo(() => new FlowService(), [])
    const authService = AuthService.getInstance()
    const [user, setUser] = useState<User | undefined>()
    const [flows, setFlows] = useState<Flow[]>([])

    const onAddFlow = async () => {
        const viewId = uuidv4()
        const flowId = uuidv4()
        const viewService = new ViewService(flowId ?? '')
        await viewService.createView({
            id: viewId,
            externalId: 0,
            parameters: {},
            title: 'New View',
            type: ViewType.View,
            flow: flowId,
            style: {
                folded: false,
            },
            description: '',
            method: 'GET',
        })
        await flowService.createFlow({
            id: flowId,
            name: 'New Flow',
            user: user?.id,
            firstView: viewId,
            parameters: { isPublic: false },
        })
        const updatedFlows = await flowService.getFlows(user?.id ?? '')
        setFlows(updatedFlows ?? [])
    }

    const onDeleteFlow = async (flow: Flow) => {
        if (flow.id === undefined) {
            return
        }
        await flowService.deleteFlow(flow.id)
        setFlows((await flowService.getFlows(user?.id ?? '')) ?? [])
    }

    useEffect(() => {
        const fetchFlows = async () => {
            try {
                if (!user) {
                    const _user = await authService?.getMe()
                    if (_user) {
                        setUser(_user)
                        const fetchedFlows = await flowService.getFlows(
                            _user?.id ?? '',
                        )
                        setFlows(fetchedFlows ?? [])
                    }
                }
            } catch (error) {
                console.error('Error fetching flows:', error)
                // Handle error appropriately
            }
        }

        fetchFlows()
    }, [authService, flowService, user]) // Dependency array ensures the effect runs only when userId changes

    function onImportFlow(input: string, file: File): void {
        const fileReader = new FileReader()
        fileReader.onloadend = async () => {
            const content = fileReader.result as string
            const flowAndElements = JSON.parse(content)
            if (flowAndElements && flowAndElements['flow']) {
                flowAndElements['flow']['name'] = input
            }
            await flowService.importCompleteFlow(flowAndElements)
            setFlows((await flowService.getFlows(user?.id ?? '')) ?? [])
        }
        fileReader.readAsText(file)
    }

    function onCancelImport(): void {
        console.log('Import was canceled')
    }

    return (
        <div className='flowList'>
            <h1>Flows</h1>
            <TableContainer component={Paper}>
                <Table>
                    <TableHead
                        style={{
                            backgroundColor: '#88889A',
                            fontWeight: 'bolder',
                        }}
                    >
                        <TableRow>
                            <TableCell>No.</TableCell>
                            <TableCell>Name</TableCell>
                            <TableCell>Description</TableCell>
                            <TableCell>
                                Actions
                                <Tooltip title='add new flow' arrow>
                                    <Button onClick={onAddFlow}>
                                        <AddIcon />
                                    </Button>
                                </Tooltip>
                                <FileUploadDialogButton
                                    title='Upload'
                                    onOK={onImportFlow}
                                    onCancel={onCancelImport}
                                    tooltip='import'
                                >
                                    <UploadIcon />
                                </FileUploadDialogButton>
                            </TableCell>
                            {/* Add other relevant columns */}
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {flows.map((flow, index) => (
                            <TableRow key={flow.id}>
                                <TableCell component='th' scope='row'>
                                    {index + 1}
                                </TableCell>
                                <TableCell>
                                    <Link to={`/edit/flows/${flow.id}`}>
                                        {flow.name}
                                    </Link>
                                </TableCell>
                                <TableCell>{flow.description}</TableCell>
                                {/* Display other flow properties as needed */}
                                <TableCell>
                                    <Tooltip title='preview' arrow>
                                    <a
                                        href={`${process.env.REACT_APP_SCANGUIDE_VIEW_URL}/${flow.id}/${flow.firstView}`}
                                        target='_blank'
                                        rel='noreferrer'
                                    >
                                        <PreviewIcon />
                                    </a>
                                  </Tooltip>
                                    <OkCancelDialogButton
                                        onOK={onDeleteFlow}
                                        title='Delete flow?'
                                        text={`Are you sure, you want to delete flow '${flow.name}'<br>This <b>cannot be undone!</b>`}
                                        callbackParameter={flow}
                                        tooltip='delete'
                                    >
                                        <DeleteForeverIcon />
                                    </OkCancelDialogButton>
                                    <Tooltip title='edit' arrow>
                                        <a href={`/edit/flows/${flow.id}`}>
                                            <EditIcon />
                                        </a>
                                    </Tooltip>
                                </TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
        </div>
    )
}

export default FlowListComponent
