import { Box, TextField, Typography, Grid } from '@mui/material'
import { useEffect, useState, useCallback } from 'react'
import { User } from '../../models/userModel'
import AuthService from '../../services/AuthService'
import {
    CustomerAddressDataModel,
    CustomerContactDataModel,
    CustomerDataModel,
    CustomerIdentityDataModel,
} from '../../models/customerData'
import React from 'react'
import './CustomerInfoComponent.css'
import { updateUser, getUserById } from '../../services/UserService'
import fontService from '../../services/FontService'
import { Font } from '../../models/fontModel'
import { AutoComplete } from '../autocomplete/autocomplete'

const CustomerInfoComponent: React.FC = () => {
    const [user, setUser] = useState<User>()
    const authService = AuthService.getInstance()
    const [customerData, setCustomerData] = useState<CustomerDataModel>(
        new CustomerDataModel(),
    )

    const [fontsList, setFontsList] = useState<Font[]>([])
    const [font, setFont] = useState<Font | null>(null)
    const [fontLoading, setFontLoading] = useState(false)

    useEffect(() => {
        const loadFonts = async () => {
            try {
                setFontLoading(true)
                const fonts = await fontService.getFonts()
                setFontsList(fonts ?? [])
            } catch (exception) {
                console.log('Exception while loading Fonts!')
            } finally {
                setFontLoading(false)
            }
        }
        loadFonts()
    }, [])

    useEffect(() => {
        if (customerData?.identityData?.mainFont) {
            setFont({ fontName: customerData?.identityData?.mainFont } as Font)
        }
    }, [customerData?.identityData?.mainFont])

    useEffect(() => {
        const loadCustomerInfo = async () => {
            try {
                const _user = await authService?.getMe()
                setUser(_user)
                if (_user !== undefined) {
                    const user: User | undefined = await getUserById(
                        _user.id ?? '',
                    )
                    if (!user) return

                    user.customerData =
                        user?.customerData ?? new CustomerDataModel()
                    user.customerData.addressData =
                        user?.customerData.addressData ??
                        new CustomerAddressDataModel()
                    user.customerData.contactData =
                        user?.customerData.contactData ??
                        new CustomerContactDataModel()
                    user.customerData.identityData =
                        user?.customerData.identityData ??
                        new CustomerIdentityDataModel()
                    setUser(user)
                    if (user.customerData) {
                        setCustomerData(user.customerData)
                    }
                }
            } catch (exception) {
                console.log('Exception while loading Customer Data!')
            }
        }
        loadCustomerInfo()
    }, [authService])

    useEffect(() => {
        // Fetch initial data from the backend if necessary
        // Example: fetchCustomerData();
    }, [])

    const handleInputChange = useCallback(
        (section: string, field: string, value: string) => {
            setCustomerData(prevState => {
                const newState = { ...prevState }
                if (section === 'address' && newState?.addressData) {
                    newState.addressData[
                        field as keyof CustomerAddressDataModel
                    ] = value
                } else if (section === 'contact' && newState?.contactData) {
                    newState.contactData[
                        field as keyof CustomerContactDataModel
                    ] = value
                } else if (section === 'identity' && newState?.identityData) {
                    newState.identityData[
                        field as keyof CustomerIdentityDataModel
                    ] = value
                } else if (section === 'legal') {
                    newState[
                        field as keyof Pick<
                            CustomerDataModel,
                            'privacyURL' | 'domainURL'
                        >
                    ] = value
                }
                return newState
            })
        },
        [],
    )

    useEffect(() => {
        if (font?.fontName) {
            handleInputChange('identity', 'mainFont', font?.fontName)
        }
    }, [font, handleInputChange])

    const sendUpdateToBackend = () => {
        const updatedUser: User = {
            ...user,
            customerData: customerData,
        }
        updateUser(user?.id ?? '', updatedUser)
        console.log('Sending data to backend:', customerData)
    }

    return (
        <Box className='CustomerInfoContainer'>
            <Typography variant='h4'>Customer Info</Typography>
            <Grid container spacing={2}>
                <Grid item xs={12} sm={6}>
                    <Box className='CustomerInfoSection'>
                        <Typography variant='h6'>Company</Typography>
                        <TextField
                            fullWidth
                            label='Name'
                            variant='outlined'
                            margin='normal'
                            value={customerData?.addressData?.name ?? ''}
                            onChange={e =>
                                handleInputChange(
                                    'address',
                                    'name',
                                    e.target.value,
                                )
                            }
                            onBlur={sendUpdateToBackend}
                        />
                        <TextField
                            fullWidth
                            label='Street'
                            variant='outlined'
                            margin='normal'
                            value={customerData?.addressData?.street ?? ''}
                            onChange={e =>
                                handleInputChange(
                                    'address',
                                    'street',
                                    e.target.value,
                                )
                            }
                            onBlur={sendUpdateToBackend}
                        />
                        <TextField
                            fullWidth
                            label='Number'
                            variant='outlined'
                            margin='normal'
                            value={customerData?.addressData?.number ?? ''}
                            onChange={e =>
                                handleInputChange(
                                    'address',
                                    'number',
                                    e.target.value,
                                )
                            }
                            onBlur={sendUpdateToBackend}
                        />
                        <TextField
                            fullWidth
                            label='City'
                            variant='outlined'
                            margin='normal'
                            value={customerData?.addressData?.city ?? ''}
                            onChange={e =>
                                handleInputChange(
                                    'address',
                                    'city',
                                    e.target.value,
                                )
                            }
                            onBlur={sendUpdateToBackend}
                        />
                        <TextField
                            fullWidth
                            label='Country'
                            variant='outlined'
                            margin='normal'
                            value={customerData?.addressData?.country ?? ''}
                            onChange={e =>
                                handleInputChange(
                                    'address',
                                    'country',
                                    e.target.value,
                                )
                            }
                            onBlur={sendUpdateToBackend}
                        />
                        <TextField
                            fullWidth
                            label='Zip Code'
                            variant='outlined'
                            margin='normal'
                            value={customerData?.addressData?.zipCode ?? ''}
                            onChange={e =>
                                handleInputChange(
                                    'address',
                                    'zipCode',
                                    e.target.value,
                                )
                            }
                            onBlur={sendUpdateToBackend}
                        />
                    </Box>
                </Grid>
                <Grid item xs={12} sm={6}>
                    <Box className='CustomerInfoSection'>
                        <Typography variant='h6'>Contact</Typography>
                        <TextField
                            fullWidth
                            label='Main Phone Number'
                            variant='outlined'
                            margin='normal'
                            value={
                                customerData?.contactData?.mainPhoneNumber ?? ''
                            }
                            onChange={e =>
                                handleInputChange(
                                    'contact',
                                    'mainPhoneNumber',
                                    e.target.value,
                                )
                            }
                            onBlur={sendUpdateToBackend}
                        />
                        <TextField
                            fullWidth
                            label='Email'
                            variant='outlined'
                            margin='normal'
                            value={customerData?.contactData?.email ?? ''}
                            onChange={e =>
                                handleInputChange(
                                    'contact',
                                    'email',
                                    e.target.value,
                                )
                            }
                            onBlur={sendUpdateToBackend}
                        />
                    </Box>
                </Grid>
                <Grid item xs={12} sm={6}>
                    <Box className='CustomerInfoSection'>
                        <Typography variant='h6'>Company Identity</Typography>
                        <label>Background</label>
                        <input
                            type='color'
                            className='CustomerInfoInput'
                            value={
                                customerData?.identityData?.backgroundColor ??
                                '#FFFFFF'
                            }
                            onChange={e =>
                                handleInputChange(
                                    'identity',
                                    'backgroundColor',
                                    e.target.value,
                                )
                            }
                            onBlur={sendUpdateToBackend}
                        />
                        <label>Buttons</label>
                        <input
                            type='color'
                            className='CustomerInfoInput'
                            value={
                                customerData?.identityData?.buttonColor ??
                                '#0000AA'
                            }
                            onChange={e =>
                                handleInputChange(
                                    'identity',
                                    'buttonColor',
                                    e.target.value,
                                )
                            }
                            onBlur={sendUpdateToBackend}
                        />
                        <label>Button Text</label>
                        <input
                            type='color'
                            className='CustomerInfoInput'
                            value={
                                customerData?.identityData?.buttonFontColor ??
                                '#FFFFFF'
                            }
                            onChange={e =>
                                handleInputChange(
                                    'identity',
                                    'buttonFontColor',
                                    e.target.value,
                                )
                            }
                            onBlur={sendUpdateToBackend}
                        />
                        <label>Primary Color</label>
                        <input
                            type='color'
                            className='CustomerInfoInput'
                            value={
                                customerData?.identityData?.fontColor ??
                                '#000000'
                            }
                            onChange={e =>
                                handleInputChange(
                                    'identity',
                                    'fontColor',
                                    e.target.value,
                                )
                            }
                            onBlur={sendUpdateToBackend}
                        />
                        <label>Secondary Color</label>
                        <input
                            type='color'
                            className='CustomerInfoInput'
                            value={
                                customerData?.identityData?.secondaryColor ??
                                '#000000'
                            }
                            onChange={e =>
                                handleInputChange(
                                    'identity',
                                    'secondaryColor',
                                    e.target.value,
                                )
                            }
                            onBlur={sendUpdateToBackend}
                        />
                        <AutoComplete
                            options={fontsList}
                            value={font}
                            setValue={setFont}
                            label='fontName'
                            loading={fontLoading}
                        />
                        {/* <TextField
                            fullWidth
                            label='Font'
                            variant='outlined'
                            margin='normal'
                            value={
                                customerData?.identityData?.mainFont ?? 'Arial'
                            }
                            onChange={e =>
                                handleInputChange(
                                    'identity',
                                    'mainFont',
                                    e.target.value,
                                )
                            }
                        /> */}
                        <TextField
                            fullWidth
                            label='LogoURL'
                            variant='outlined'
                            margin='normal'
                            value={
                                customerData?.identityData?.logoURL ??
                                'https://app.scanguide.de/ScanGuide_Logo.png'
                            }
                            onChange={e =>
                                handleInputChange(
                                    'identity',
                                    'logoURL',
                                    e.target.value,
                                )
                            }
                            onBlur={sendUpdateToBackend}
                        />
                    </Box>
                </Grid>
                <Grid item xs={12} sm={6}>
                    <Box className='CustomerInfoSection'>
                        <Typography variant='h6'>Legal</Typography>
                        <TextField
                            fullWidth
                            label='Privacy Statement'
                            variant='outlined'
                            margin='normal'
                            value={
                                customerData?.privacyURL ??
                                'https://dev4agriculture.de/conditions-of-use/'
                            }
                            onChange={e =>
                                handleInputChange(
                                    'legal',
                                    'privacyURL',
                                    e.target.value,
                                )
                            }
                            onBlur={sendUpdateToBackend}
                        />
                        <TextField
                            fullWidth
                            label='Domain'
                            variant='outlined'
                            margin='normal'
                            value={
                                customerData?.domainURL ??
                                'https://dev4agriculture.de/privacy-notice/'
                            }
                            onChange={e =>
                                handleInputChange(
                                    'legal',
                                    'domainURL',
                                    e.target.value,
                                )
                            }
                            onBlur={sendUpdateToBackend}
                        />
                    </Box>
                </Grid>
            </Grid>
        </Box>
    )
}

export default CustomerInfoComponent
