import {
    Autocomplete,
    Box,
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Link,
    Modal,
    Stack,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow,
    TextField,
    Tooltip,
    Typography,
} from '@mui/material'
import { Flow } from '../../models/flowModel'
import {
    DataGrid,
    GridColDef,
    GridDeleteIcon,
    GridEventListener,
    GridRowParams,
} from '@mui/x-data-grid'
import { useCallback, useEffect, useMemo, useState } from 'react'
import AddIcon from '@mui/icons-material/Add'
import StringInputDialog from '../Alert/StringInputDialog'
import { FieldService } from '../../services/FieldsService'
import { DataService } from '../../services/DataService'
import { Field } from '../../models/fieldsModel'
import { Data, DataFields } from '../../models/dataModel'
import SelfInputField from '../Base/SelfInputField'
import EditIcon from '@mui/icons-material/Edit'
import SyncIcon from '@mui/icons-material/Sync'
import { v4 as uuidv4 } from 'uuid'
import { Close } from '@mui/icons-material'
import PreviewIcon from '@mui/icons-material/Preview'
import { useParams } from 'react-router-dom'

export interface FlowDataAreaProps {
    flow: Flow
}

const FlowDataArea = ({ flow }: FlowDataAreaProps) => {
    const { flowId } = useParams()
    const [columns, setColumns] = useState<GridColDef[]>([])
    const [rows, setRows] = useState<Record<string, string>[]>([])
    const [_fields, setFields] = useState<Field[]>([])
    const [currentData, setCurrentData] = useState<Data>({
        flow: flow.id ?? '',
        fields: {},
    })
    const [dataList, setDataList] = useState<Data[]>([])
    const [editDataTitle, setEditDataTitle] = useState<string>('')

    const [isAddFieldModalOpen, setIsAddFieldModalOpen] = useState(false)
    const [isAddDataDialogOpen, setIsAddDataDialogOpen] = useState(false)
    const [isModalOpen, setIsModalOpen] = useState<'edit' | 'delete' | null>(
        null,
    )
    const [fieldsList, setFieldsList] = useState<Field[]>([])
    const [newFieldName, setNewFieldName] = useState<string>('')
    const [selectedField, setSelectedField] = useState<Field | null>(null)

    const fieldsService = useMemo(() => new FieldService(flow), [flow])
    const dataService = useMemo(() => new DataService(flow), [flow])

    const openAddFieldModal = () => setIsAddFieldModalOpen(true)
    const closeAddFieldModal = () => setIsAddFieldModalOpen(false)

    const openAddDataDialog = () => setIsAddDataDialogOpen(true)
    const closeAddDataDialog = () => setIsAddDataDialogOpen(false)

    const fetchFields = useCallback(async () => {
        const fields = await fieldsService.getFieldsByFlow()
        console.log(fields, 'fieldsList')
        setFieldsList(fields ?? [])
    }, [])

    useEffect(() => {
        fetchFields()
    }, [isModalOpen])

    const handleAddFieldModalSubmit = async (inputValue: string) => {
        await fieldsService.createField({
            id: uuidv4(),
            flow: flow.id ?? '',
            index: columns.length + 1,
            name: inputValue,
            type: 'string',
        })
        updateFields()
    }

    const onAddOrEditDataEvent = async () => {
        if (currentData.id !== undefined && currentData.id !== '') {
            console.log('Updating Data: ' + currentData.id)
            await dataService.updateData(currentData.id, currentData)
        } else {
            currentData.id = uuidv4()
            console.log('Adding Data: ' + JSON.stringify(currentData))
            await dataService.createData(currentData)
        }
        updateData()
        closeAddDataDialog()
    }

    const updateData = useCallback(
        async (updFields?: Field[] | undefined) => {
            const updatedDataList = await dataService.getDataByFlow()

            if (updatedDataList === undefined) {
                return
            }

            if (!updFields) {
                updFields = _fields
            }

            const updatedRows: Record<string, string>[] = []
            updatedDataList.forEach(entry => {
                const row: Record<string, string> = {}
                row.id = (entry.id ?? '').toString()
                const keys = Object.keys(entry.fields ?? {})
                keys.forEach(key => {
                    row[key] = (entry.fields[key] as string) ?? ''
                })
                row['name'] = entry.name ?? ''
                if (updFields !== undefined) {
                    updFields.forEach(element => {
                        if (entry.fields != null) {
                            row[element.name] =
                                (entry.fields[element.name] as string) ?? ''
                        } else {
                            row[element.name] = ''
                        }
                    })
                }
                updatedRows.push(row)
            })
            setRows(updatedRows)
            setDataList(updatedDataList)
        },
        [_fields, dataService],
    )

    const updateFields = useCallback(async (): Promise<Field[]> => {
        const updatedFields = await fieldsService.getFieldsByFlow()

        const updatedColumns: GridColDef[] = []

        updatedFields
            ?.sort((a, b) => (a.index > b.index ? 1 : -1))
            .forEach(entry => {
                updatedColumns.push({
                    field: entry.name,
                    headerName: entry.name,
                    width: 200,
                })
            })

        setColumns([
            {
                field: 'actions',
                headerName: 'Actions',
                width: 150,
                renderCell: params => {
                    const deleteFlowDataEvent = async () => {
                        await dataService.deleteData(params.row.id)
                        updateData()
                    }
                    const editData = async () => {
                        setEditDataTitle('Edit Data')
                        openAddDataDialog()
                        await dataService.updateData(params.row.id, params.row)
                        updateData()
                    }
                    return (
                        <Stack flexDirection='row' alignItems='center'>
                            <Tooltip title='Edit' arrow>
                                <Button
                                    sx={{ width: 'fit-content', minWidth: '0' }}
                                    color='primary'
                                    onClick={editData}
                                >
                                    <EditIcon />
                                </Button>
                            </Tooltip>
                            <Tooltip title='Delete' arrow>
                                <Button
                                    sx={{ width: 'fit-content', minWidth: '0' }}
                                    color='primary'
                                    onClick={deleteFlowDataEvent}
                                >
                                    <GridDeleteIcon />
                                </Button>
                            </Tooltip>
                            <Tooltip title='View' arrow>
                            <Button
                                sx={{ width: 'fit-content', minWidth: '0' }}
                            >
                                <Link
                                    height='24px'
                                    href={
                                        `${process.env.REACT_APP_SCANGUIDE_VIEW_URL}` + "/" +
                                        flowId +
                                        '?data=' +
                                        params.row.id
                                    }
                                    target='_blank'
                                    rel='noreferrer'
                                    >
                                        <PreviewIcon />
                                    </Link>
                                </Button>
                            </Tooltip>
                        </Stack>
                    )
                },
            },
            {
                field: 'id',
                headerName: 'ID',
                width: 80,
            },
            {
                field: 'name',
                headerName: 'Name',
                width: 140,
            },
            ...updatedColumns,
        ])

        setFields(updatedFields ?? [])
        return updatedFields ?? []
    }, [dataService, fieldsService, updateData])

    useEffect(() => {
        console.log('Calling useEffect updateData')
        const loadAll = async () => {
            console.log('Calling loadAll')
            if (flow && flow.id) {
                const updFields = await updateFields()
                console.log(
                    'Calling UpdateData. Fields: ' + JSON.stringify(_fields),
                )
                await updateData(updFields)
            }
        }
        loadAll()
    }, [flow])

    const onReload = async () => {
        await updateFields()
        await updateData()
    }

    const onCreateDataEvent = () => {
        const data: Data = {
            id: '',
            flow: flow.id ?? '',
            fields: _fields.reduce((acc, field) => {
                acc[field.name] = ' ' // Initialize each field with null or another default value
                return acc
            }, {} as DataFields),
        }
        setCurrentData(data)
        setEditDataTitle('Add new Data')
        openAddDataDialog()
    }

    // const onEditDataEvent = () => {
    //     setEditDataTitle('Edit Data')
    //     openAddDataDialog()
    // }

    const onDataRowClickEvent: GridEventListener<'rowClick'> = (
        params: GridRowParams,
    ) => {
        console.log('DataRowClick')
        const id = params.row.id
        if (id !== undefined) {
            console.log('ID is: ' + id)
            const updatedData = dataList.filter(entry => entry.id === id)[0]
            if (updatedData !== undefined) {
                console.log('Updated Data exists!')
                if (updatedData.fields == null) {
                    updatedData.fields = _fields.reduce((acc, field) => {
                        acc[field.name] = '' // Initialize each field with null or another default value
                        return acc
                    }, {} as DataFields)
                } else {
                    _fields.forEach(entry => {
                        if (updatedData.fields[entry.name] === undefined) {
                            updatedData.fields[entry.name] = ''
                        }
                    })
                }
                setCurrentData(updatedData)
            }
        }
    }

    const handleDeleteField = async () => {
        try {
            await fieldsService.deleteField(selectedField?.id ?? '')
        } catch (e) {
            console.error(e)
        }
        await updateFields()
        setSelectedField(null)
        setIsModalOpen(null)
        setNewFieldName('')
    }

    const handleEditField = async () => {
        try {
            await fieldsService.updateField(selectedField?.id ?? '', {
                ...(selectedField ?? ({} as Field)),
                name: newFieldName,
            })
        } catch (e) {
            console.error(e)
        }
        await updateFields()
        setSelectedField(null)
        setIsModalOpen(null)
        setNewFieldName('')
    }

    return (
        <div>
            <Box
                sx={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                }}
            >
                <Box sx={{ display: 'flex', alignItems: 'flex-start' }}>
                    <Typography variant='h5'>Data</Typography>
                    <Button
                        color='primary'
                        startIcon={<AddIcon />}
                        onClick={onCreateDataEvent}
                    >
                        Add
                    </Button>
                </Box>
                <Box sx={{ display: 'flex', alignItems: 'center' }}>
                    <Typography variant='h5'>Fields</Typography>
                    <Button
                        color='primary'
                        startIcon={<AddIcon />}
                        onClick={openAddFieldModal}
                    >
                        Add
                    </Button>
                    <Button
                        color='primary'
                        startIcon={<EditIcon />}
                        onClick={() => setIsModalOpen('edit')}
                    >
                        Edit
                    </Button>
                    <Button
                        color='primary'
                        startIcon={<GridDeleteIcon />}
                        onClick={() => setIsModalOpen('delete')}
                    >
                        Delete
                    </Button>
                </Box>
                <Box sx={{ display: 'flex', alignItems: 'flex-end' }}>
                    <Button
                        color='primary'
                        startIcon={<SyncIcon />}
                        onClick={onReload}
                    >
                        Reload all
                    </Button>
                </Box>
            </Box>
            <StringInputDialog
                isOpen={isAddFieldModalOpen}
                onClose={closeAddFieldModal}
                onSubmit={handleAddFieldModalSubmit}
                defaultValue='Field'
                title='Add field'
                text='Enter Field name'
            />
            <DataGrid
                columns={columns}
                rows={rows}
                onRowClick={onDataRowClickEvent}
            />

            <Dialog
                open={isAddDataDialogOpen}
                onClose={() => {
                    closeAddDataDialog()
                }}
                aria-labelledby='alert-dialog-title'
                aria-describedby='alert-dialog-description'
            >
                <DialogTitle id='alert-dialog-title'>
                    {editDataTitle}
                </DialogTitle>
                <DialogContent>
                    <Table>
                        <TableHead>
                            <TableRow>
                                <TableCell>Name</TableCell>
                                <TableCell>Value</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            <TableRow key={'add_data_id'}>
                                <TableCell>ID</TableCell>
                                <TableCell>{currentData.id ?? 'New'}</TableCell>
                            </TableRow>
                            <TableRow key={'add_data_name'}>
                                <TableCell>Name</TableCell>
                                <TableCell>
                                    <SelfInputField
                                        onChange={(value: string) => {
                                            const updatedData = currentData
                                            updatedData.name = value
                                            setCurrentData(updatedData)
                                        }}
                                        value={currentData.name ?? ''}
                                    />
                                </TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell>Fields:</TableCell>
                            </TableRow>
                            {currentData &&
                                currentData.fields &&
                                _fields?.map(entry => {
                                    console.log('Field: ' + entry.name)
                                    if (currentData !== undefined) {
                                        if (
                                            currentData.fields[entry.name] ===
                                            undefined
                                        ) {
                                            currentData.fields[entry.name] = ''
                                        }
                                        return (
                                            <TableRow
                                                key={'add_data_' + entry.index}
                                            >
                                                <TableCell>
                                                    {entry.name}
                                                </TableCell>
                                                <TableCell>
                                                    <SelfInputField
                                                        onChange={(
                                                            value: string,
                                                        ) => {
                                                            const updatedData =
                                                                currentData
                                                            updatedData.fields[
                                                                entry.name
                                                            ] = value
                                                            setCurrentData(
                                                                updatedData,
                                                            )
                                                        }}
                                                        value={
                                                            (currentData.fields[
                                                                entry.name
                                                            ] as string) ?? ''
                                                        }
                                                    />
                                                </TableCell>
                                            </TableRow>
                                        )
                                    } else {
                                        return <></>
                                    }
                                })}
                        </TableBody>
                    </Table>
                </DialogContent>
                <DialogActions>
                    <Button
                        onClick={() => {
                            onAddOrEditDataEvent()
                        }}
                    >
                        Save
                    </Button>
                    <Button
                        onClick={() => {
                            closeAddDataDialog()
                        }}
                        autoFocus
                    >
                        Cancel
                    </Button>
                </DialogActions>
            </Dialog>
            <Modal open={!!isModalOpen}>
                <Stack
                    sx={{
                        position: 'absolute',
                        inset: 0,
                        margin: 'auto',
                        backgroundColor: 'white',
                        height: 'fit-content',
                        width: 'fit-content',
                        padding: '30px',
                        borderRadius: '10px',
                        gap: '20px',
                    }}
                >
                    <Button
                        onClick={() => setIsModalOpen(null)}
                        sx={{
                            position: 'absolute',
                            top: 0,
                            right: 0,
                            minWidth: 0,
                        }}
                    >
                        <Close />
                    </Button>
                    <Typography>
                        {(isModalOpen as string)?.charAt(0).toUpperCase() +
                            (isModalOpen as string)?.slice(1)}{' '}
                        field
                    </Typography>
                    <Autocomplete
                        options={fieldsList}
                        sx={{ width: 300 }}
                        renderInput={params => (
                            <TextField {...params} label='Field' />
                        )}
                        getOptionLabel={option => option.name}
                        onChange={(_, value) => {
                            setSelectedField(value)
                        }}
                    />
                    {isModalOpen === 'edit' && (
                        <TextField
                            label='New field name'
                            value={newFieldName}
                            onChange={e => setNewFieldName(e.target.value)}
                        />
                    )}
                    <Stack flexDirection='row' gap='10px'>
                        {isModalOpen === 'delete' && (
                            <Button onClick={handleDeleteField}>Delete</Button>
                        )}
                        {isModalOpen === 'edit' && (
                            <Button onClick={handleEditField}>Edit</Button>
                        )}
                        <Button onClick={() => setIsModalOpen(null)}>
                            Cancel
                        </Button>
                    </Stack>
                </Stack>
            </Modal>
        </div>
    )
}

export default FlowDataArea
